<template>
	<div class="headLine">
		<div class="headLeft">
			<div class="headImg"><img :src="avatar" /></div>
			<div class="tiezhuzhe">
				<div class="name">
					{{author}}
					<img class="guangfangtag" v-if="user_groupid=='1'?true:false"
						src="@/assets/images/content_guangfang.png" />
				</div>
				<div class="time">{{timeChange}}</div>
			</div>
		</div>
		<div class="gongzhuBtn" v-if="userInfo.uid!=authorid" @click="doNotice(authorid)">{{noticeTxt}}</div>
	</div>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import request from '@/service/request'
	import {
		Toast
	} from 'vant';
	import common from '@/util/common';
	export default defineComponent({
		data() {
			return {
				noticeTxt: '关注',
				userInfo: {}
			}
		},
		props: {
			author: {
				default: '',
				type: String
			},
			authorid: {
				default: '',
				type: String
			},
			dateline: {
				default: '',
				type: String
			},
			avatar: {
				default: '',
				type: String
			},
			user_groupid: {
				default: '0',
				type: String
			},
			is_follow: {
				default: 0,
				type: Number
			}
		},
		watch: {
			is_follow(newVal, oldVal) {
				if (newVal == 1) {
					this.noticeTxt = "已关注";
				} else {
					this.noticeTxt = "关注";
				}
			}
		},
		computed: {
			timeChange() {
				var date1 = new Date(parseInt(this.dateline) * 1000);
				var date2 = new Date().getTime();
				var date3 = date2 - date1 //时间差的毫秒数 
				//计算出相差天数 
				var days = Math.floor(date3 / (24 * 3600 * 1000))
				//计算出小时数 
				var leave1 = date3 % (24 * 3600 * 1000)
				var hours = Math.floor(leave1 / (3600 * 1000))
				//计算相差分钟数 
				var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数 
				var minutes = Math.floor(leave2 / (60 * 1000))
				//计算相差秒数 
				var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数 
				var seconds = Math.round(leave3 / 1000);
				var result = ""
				if (days > 0) {
					var Y = date1.getFullYear() + '-';
					var M = (date1.getMonth() + 1 < 10 ? '0' + (date1.getMonth() + 1) : date1.getMonth() +
						1) + '-';
					var D = date1.getDate() + ' ';
					result = Y + M + D
				} else if (hours > 0) {
					result = hours + "小时前"
				} else if (minutes > 0) {
					result = minutes + "分钟前"
				} else if (seconds > 0) {
					result = seconds + "秒前"
				}
				//}
				return result
			}
		},
		created() {
			if (this.is_follow == 1) {
				this.noticeTxt = "已关注"
			}
			var userInfo = window.localStorage.getItem('userInfo');
			if (userInfo) {
				this.userInfo = JSON.parse(userInfo)
			}
		},
		methods: {
			doNotice(follow_uid) {
				if (common.isLogin()) {
					var smData = {};
					smData.token = window.localStorage.getItem('token');
					smData.follow_uid = follow_uid;
					request.apiAxios({
						method: 'post',
						url: request.FOLLOW,
						data: smData
					}).then((res) => {
						if (res.status) {
							if (res.data.is_follow == 1) {
								this.noticeTxt = "已关注"
								Toast("关注成功！");
								this.$parent.$parent.doFollowSet(follow_uid, 1)
							} else {
								this.noticeTxt = "关注"
								Toast("取消关注成功！");
								this.$parent.$parent.doFollowSet(follow_uid, 0)
							}

						} else {
							Toast(res.message);
						}
					}).catch((err) => {
						console.log(err);
					})
				}
			}
		}
	});
</script>

<style lang="less" scoped="scoped">
	.headLine {
		display: flex;
		align-items: center;
		margin-bottom: 0.12rem;
	}

	.headLeft {
		margin-right: 0.2rem;
		flex: auto;
		display: flex;
		color: #5D6262;
	}

	.headImg {
		width: 0.88rem;
		height: 0.88rem;
		background: #EBEDDE;
		border-radius: 0.07rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 0.2rem;

		img {
			max-width: 100%;
			max-height: 100%;
			border-radius: 0.07rem;
		}
	}

	.tiezhuzhe {
		display: flex;
		flex-direction: column;

		.name {
			display: flex;
			align-items: center;
			font-size: 0.34rem;
			margin-bottom: 0.14rem;
			color: #5D6262;
		}

		.time {
			font-size: 0.25rem;
			color: #5D6262;
		}
	}

	.guangfangtag {
		width: 0.78rem;
		height: 0.38rem;
		margin-left: 0.4rem;
		flex: none;
	}

	.gongzhuBtn {
		flex: none;
		width: 1.22rem;
		height: 0.64rem;
		background: #B4EDE2;
		box-shadow: 0px 1px 2px 0px rgba(161, 161, 133, 0.36);
		border-radius: 0.32rem;
		line-height: 0.64rem;
		text-align: center;
		color: #00656E;
		font-size: 0.3rem;
	}
</style>
