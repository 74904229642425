<template>
	<!--标签行-->
	<div class="tagDiv">
		<span class="tagitem" v-for="(item,index) in smData" :key="index">{{item}}</span>
	</div>
</template>

<script>
	import { defineComponent } from 'vue'
	export default defineComponent({
		props: {
			smData:{
				type:Array,
				default:()=>{
					return []
				}
			}
		}
	});
</script>

<style lang="less" scoped="scoped">
	.tagDiv {
		margin-bottom: 0.13rem;
	}
	
	.tagitem {
		min-width: 1.64rem;
		height: 0.49rem;
		background: #B4EDE2;
		border-radius: 0.25rem;
		color: #227D7C;
		padding: 0.13rem 0.28rem;
	}	
</style>
