<template>
	<div class="pinglunBox commBlock">
		<div class="pinglunTitle">
			<span>全部评论（{{totalNum}}）</span>
			<div class="dropDownBox">
				<van-dropdown-menu class="rightDropDown" active-color="#73D1C6">
					<van-dropdown-item v-model="value1" :options="option1" />
				</van-dropdown-menu>
			</div>
		</div>
		<van-list v-model:loading="loading" :finished="finished" :finished-text="finishTxt" @load="loadData">
			<div class="pinglunDiv">
				<div class="pinglunItem" v-for="(item,index) in listData" :key="index">
					<div class="headICON">
						<img :src="item.avatar" />
					</div>
					<div class="pinglunDetail">
						<div class="pinglunAuth">
							<div class="Auth">
								<div class="name">{{item.author}}</div>
								<div class="floor">{{item.floor}}F</div>
							</div>
							<van-button v-preventReClick="1000" class="dianzhan" :class="item.is_like?'yes':''"
								@click="doLikeFun(item.pid,index)">
								<img class="dianzhanIcon no" src="@/assets/images/content_dianzhan.png" />
								<img class="dianzhanIcon yes" src="@/assets/images/content_dianzhan_h.png" />
								<span>{{item.like_num}}</span>
							</van-button>
							<!-- <div class="dianzhan">
								<img class="dianzhanIcon" src="../../assets/images/content_dianzhan.png"/> {{item.like_num}}
							</div> -->
						</div>
						<div class="pingluncontent htmlDiv" @click="showAnswerpinglunFun(item.message,item.pid)"
							v-html="item.message">
						</div>
						<div class="pinglunAnswerList" v-if="item.subpost&&item.subpost.length>0">
							<div class="pinlunAnswerItem" v-for="(child,idx) in item.subpost" :key="idx"
								@click="showAnswerpinglunFun(child.message,item.pid,child.authorid,child.nickname,child.pid)">
								<div class="answerAuth">{{child.nickname}}:</div>
								<div class="answerContent">{{child.message}}</div>
							</div>
							<!-- <div class="pinlunAnswerItem" v-if="showMore" @click="showAnswerpinglunFun('type2')">
								<div class="answerAuth">不困不睡觉:</div>
								<div class="answerContent">都是大佬，我两个月才210</div>
							</div> -->
							<div class="pingluntotal" v-if="item.isMore" @click="showMoreFun(index,item.pid,item.page)">
								共{{item.hideNum}}条评论
								>> </div>
						</div>
					</div>
				</div>
			</div>
		</van-list>
		<div class="answerpinglunBox">
			<van-popup v-model:show="showAnswerPinglun" round position="bottom"
				:style="{ 'min-height': '30%','max-height':'60%'}">
				<ul>
					<li class="cont">{{anserMessage}}</li>
					<li @click="replayTieFun">回复</li>
					<li @click="reportPostFun">举报</li>
				</ul>
				<div class="cancelBtn" @click="closeAnserpinglun">取消</div>
			</van-popup>
		</div>
	</div>
	<!--举报分类-->
	<SELECTTYPE v-if="showReportTypes" :pid="reportPid" :isShow="showReportTypes"></SELECTTYPE>
</template>

<script>
	import {
		defineComponent
	} from 'vue'
	import {
		DropdownMenu,
		DropdownItem,
		Popup,
		Slots,
		List,
		Toast,
		Button
	} from 'vant';
	import request from '@/service/request';
	import common from '@/util/common';
	import SELECTTYPE from '@/components/reportTypes/selectType.vue'

	export default defineComponent({
		components: {
			[DropdownMenu.name]: DropdownMenu,
			[DropdownItem.name]: DropdownItem,
			[Popup.name]: Popup,
			[List.name]: List,
			[Button.name]: Button,
			SELECTTYPE
		},
		data() {
			return {
				option1: [{
					text: '时间',
					value: 0
				}],
				value1: 0,
				showMore: false,
				listData: [],
				page: 2,
				showAnswerPinglun: false,
				loading: false, //是否处于加载状态，加载过程中不触发 load 事件
				finishTxt: "没有更多了",
				finished: true,
				anserMessage: '',
				curpid: '',
				showReportTypes: false,
				reportPid: '',
				totalNum: 0
			}
		},
		props: {
			tid: {
				type: String,
				default: ''
			},
			total: {
				type: Number,
				default: 0
			},
			pinglunList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		created() {

			setTimeout(() => {
				console.log(this.pinglunList, this.pinglunList.length, 'pl');

				this.listData = this.fiflterData(this.pinglunList);
				if (this.listData.length == 0) {
					this.finished = true;
					this.loading = false;
				} else {
					this.finished = false;
					this.loading = false;
				}
				this.totalNum = this.total;
			}, 1000);

		},
		watch: {
			total(newVal, oldVal) {
				this.totalNum = newVal;
			}
		},
		methods: {
			//点击举报
			reportPostFun() {
				if (common.isLogin()) {
					this.showReportTypes = true;
				}
			},
			//点击点赞
			doLikeFun(pid, index) {
				if (common.isLogin()) {
					request.apiAxios({
						method: 'post',
						url: request.DOLIKES,
						data: {
							token: localStorage.getItem('token'),
							pid: pid
						}
					}).then((res) => {
						if (res.status) {
							debugger;
							if (res.data.is_like) {
								Toast("点赞成功！");
								this.listData[index].like_num++;
								this.listData[index].is_like = 1;
							} else {
								Toast("取消点赞！");
								this.listData[index].like_num--;
								this.listData[index].is_like = 0;
							}
						} else {
							Toast(res.message);
						}
					}).catch((err) => {
						console.log(err);
					})
				}
			},
			showMoreFun(index, pid, page) {

				request.apiAxios({
					method: 'post',
					url: request.VIEWPOSTREPLY,
					data: {
						token: localStorage.getItem('token'),
						pid: pid,
						page: page
					}
				}).then((res) => {
					if (res.status) {
						if (res.data.data.list.length > 0) {
							this.listData[index].isMore = true;
							this.listData[index].subpost = this.listData[index].subpost.concat(res.data
								.data.list);
						} else {
							this.listData[index].isMore = false;
						}
						this.listData[index].page++;
						var num = (this.listData[index].reply_num - (this.listData[index].page - 1) * 10)
						this.listData[index].hideNum = num >= 0 ? num : 0
						if (this.listData[index].hideNum == 0) {
							this.listData[index].isMore = false
						} else {
							this.listData[index].isMore = true;
						}
					}
				}).catch((err) => {
					console.log(err);
				})

			},
			getList() {
				request.apiAxios({
					method: 'post',
					url: request.VIEWTHREAD,
					data: {
						tid: this.tid,
						type: 'pinglun',
						page: this.page
					}
				}).then((res) => {
					if (res.status) {
						this.page++;
						if (res.data.data.list.length > 0) {
							if (this.listData.length == 0) {
								this.listData = this.fiflterData(res.data.data.list);
							} else {
								this.listData = this.listData.concat(this.fiflterData(res.data.data.list));
							}
							this.finished = false;
						} else {
							this.finished = true;
						}
						this.loading = false;
					}
				}).catch((err) => {
					console.log(err);
					this.finished = true;
				})
			},
			fiflterData(list) {
				for (var i = 0; i < list.length; i++) {
					list[i].page = 2;
					if (list[i].subpost.length >= 10) {

						var num = (list[i].reply_num - (list[i].page - 1) * 10)
						list[i].hideNum = num >= 0 ? num : 0
						if (list[i].hideNum == 0) {
							list[i].isMore = false
						} else {
							list[i].isMore = true;
						}
					} else {
						list[i].isMore = false;
						list[i].hideNum = 0;
					}

					if (!list[i].subpost) list[i].subpost = [];

				}
				return list;
			},
			loadData() {
				this.getList();
			},
			showAnswerpinglunFun(content, curpid, at_uid, name, childpid) {
				if (common.isLogin()) {
					debugger;
					this.anserMessage = content;
					this.curpid = curpid;
					this.reportPid = curpid;
					if (at_uid) {
						this.at_uid = at_uid;
						this.preTishi = '@' + name;
						this.reportPid = childpid;
					}
					this.showAnswerPinglun = true;
				}
			},
			replayTieFun() {
				if (common.isLogin()) {
					this.closeAnserpinglun();
					if (this.at_uid) {
						this.$parent.showReplay(this.curpid, this.at_uid, this.preTishi);
					} else {
						this.$parent.showReplay(this.curpid)
					}
				}
			},
			//回复帖子成功！
			replayTieSuccess(res) {
				console.log(res);
				if (!res.data.subpost) res.data.subpost = [];
				if (this.listData.length == 0) {
					this.listData = [res.data];
				} else {
					this.listData = [res.data].concat(this.listData);
				}
			},
			//回复评论成功！
			replayTieChildSuccess(res) {
				for (var i = 0; i < this.listData.length; i++) {
					if (this.curpid == this.listData[i].pid) {
						this.listData[i].subpost = [res.data].concat(this.listData[i].subpost);
						break;
					}
				}
			},
			closeReportTypes() {
				this.showReportTypes = false;
				this.closeAnserpinglun();
			}
		},
		setup() {
			function closeAnserpinglun() {
				this.showAnswerPinglun = false;
			}
			return {
				closeAnserpinglun
			}
		},

	});
</script>

<style lang="less" scoped="scoped">
	.cont {
		max-height: 3rem;
		overflow: auto;
	}

	.pinglunDiv {
		max-width: 100%;
		overflow-x: hidden;
	}

	.pinglunItem {
		padding: 0.5rem 0 0.3rem;
		display: flex;

		.headICON {
			width: 0.64rem;
			height: 0.64rem;
			border-radius: 0.1rem;
			flex: none;
			margin-right: 0.18rem;

			img {
				width: 0.64rem;
				height: 0.64rem;
			}
		}

		.pinglunDetail {
			flex: auto;
			border-bottom: 1px solid #D5D5D5;
			padding-bottom: 0.22rem;
		}

		.pinglunAuth {
			display: flex;
			margin-bottom: 0.25rem;

			.Auth {
				flex: auto;
				color: #959B9B;
				font-size: 0.25rem;

				.name {
					margin-bottom: 0.1rem;
				}
			}

			.dianzhan {
				flex: none;
				color: #5D6262;
				font-size: 0.28rem;

				.dianzhanIcon {
					width: 0.24rem;
					height: 0.24rem;
					margin-right: 0.2rem;
				}
			}
		}

		.pingluncontent {
			font-size: 0.28rem;
			color: #5D6262;
			margin-bottom: 0.19rem;
		}

		.pinglunAnswerList {
			background: #E6E7DF;
			padding: 0.16rem;
			border-radius: 0.07rem;
		}

		.pinlunAnswerItem {
			margin-bottom: 0.2rem;

			.answerAuth {
				font-size: 0.25rem;
				color: #959B9B;
				margin-bottom: 0.1rem;
			}

			.answerContent {
				font-size: 0.28rem;
				color: #5D6262;
				word-break: break-all;
				overflow: hidden;
			}

		}

		.pingluntotal {
			font-size: 0.25rem;
			color: #959B9B;
		}

		&:last-child {
			.pinglunDetail {
				border-bottom: none;
			}
		}
	}
</style>
<style lang="less">
	.htmlDiv {
		img {
			max-width: 100%;
		}
	}

	.pinglunTitle {
		color: #5D6262;

		.van-dropdown-menu__title {
			color: #5D6262;
		}
	}

	.pinglunAuth {
		.van-button {
			background: none;
			border: none;

			.yes {
				display: none;
			}

			&.yes {
				color: #f08d8c;

				.yes {
					display: inline-block;
				}

				.no {
					display: none;
				}
			}
		}
	}
</style>
