<template>
	<!--点赞等功能行-->
	<div class="functionDiv" v-if="from!='detail'">
		<van-button v-preventReClick="1000" class="functionItem" :class="isfavorite?'yes':''" @click="doFavoriteFun">
			<img class="no" src="@/assets/images/content_shuochang_new.png" />
			<img class="yes" src="@/assets/images/content_shuochang_newH.png" />
			<span>收藏</span>
		</van-button>
		<van-button v-preventReClick="1000" class="functionItem" @click="pinglunFun">
			<img src="@/assets/images/content_pinglun.png" /><span>评论{{reply_num}}</span>
		</van-button>
		<van-button v-preventReClick="1000" class="functionItem" :class="isdolike?'yes':''" @click="doLikeFun">
			<img class="no" src="@/assets/images/content_dianzhan.png" />
			<img class="yes" src="@/assets/images/content_dianzhan_h.png" />
			<span>{{likeNum}}</span>
		</van-button>
		<van-button v-preventReClick="1000" class="functionItem" @click="doMore"><img
				src="@/assets/images/content_more.png" /></van-button>
	</div>
	<!--详情页点赞底部-->
	<div class="pinglunFunBox" v-else>
		<div class="pinglunFunDiv">
			<van-button class="functionItem" v-preventReClick="1000" :class="isfavorite?'yes':''"
				@click="doFavoriteFun">
				<img class="no" src="@/assets/images/content_shuochang_neww.png" />
				<img class="yes" src="@/assets/images/content_shuochang_newH.png" />
				<span>收藏</span>
			</van-button>
			<van-button class="functionItem" v-preventReClick="1000" @click="showPinglunAlert">
				<img src="@/assets/images/content_pinglun_w.png" />
				<span>评论{{reply_num}}</span>
			</van-button>
			<van-button class="functionItem" v-preventReClick="1000" :class="isdolike?'yes':''" @click="doLikeFun">
				<img class="no" src="@/assets/images/content_dianzhan_w.png" />
				<img class="yes" src="@/assets/images/content_dianzhan_h.png" />
				<span>{{likeNum}}</span>
			</van-button>
		</div>
	</div>

	<!--点击更多-->
	<REPORTTYPES :show="showMore" v-if="showMore" :pid="pid"></REPORTTYPES>


</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import request from '@/service/request';
	import {
		Toast,
		Button
	} from "vant";
	import common from '@/util/common';
	import REPORTTYPES from '@/components/reportTypes/reportTypes.vue'


	export default defineComponent({
		components: {
			REPORTTYPES,
			[Button.name]: Button
		},
		props: {
			like_num: {
				type: Number,
				default: 0
			},
			favorite: {
				type: Number,
				default: 0
			},
			dolike: {
				type: Number,
				default: 0
			},
			tid: {
				type: String,
				default: ''
			},
			authorid: {
				type: String,
				default: ''
			},
			from: {
				type: String,
				default: ''
			},
			pid: {
				type: String,
				default: ''
			},
			index: {
				type: Number,
				default: 0
			},
			reply_num: {
				type: Number,
				default: 0
			}
		},
		watch: {
			favorite(newVal, oldVal) {
				console.log(this.isfavorite, newVal, 'this.isfavorite', oldVal, '侦测到更新了');
				this.isfavorite = newVal;
			},
			dolike(newVal, oldVal) {
				console.log(this.isdolike, newVal, 'this.isdolike', oldVal)
				this.isdolike = newVal;
			},
			like_num(newVal, oldVal) {
				console.log(this.likeNum, newVal, 'this.likeNum', oldVal);
				this.likeNum = newVal;
			},
			reply_num(newVal, oldVal) {
				this.replyNum = newVal;
			}
		},
		data() {
			return {
				isfavorite: 0,
				isdolike: 0,
				likeNum: 0,
				showMore: false,
				replyNum: 0
			}
		},
		created() {
			if (this.from == 'detail') {
				console.log(this.favorite, this.dolike, 'detail')
			}
			if (this.favorite)
				this.isfavorite = this.favorite;
			if (this.dolike)
				this.isdolike = this.dolike;
			if (this.reply_num)
				this.replyNum = this.reply_num;
			this.likeNum = this.like_num ? this.like_num : 0;
		},

		methods: {
			//点击评论
			pinglunFun() {
				if (common.isLogin()) {
					this.$parent.$parent.goDeatil(this.tid, this.authorid, true,this.index);
				}
			},
			//点击收藏
			doFavoriteFun() {
				if (common.isLogin()) {
					request.apiAxios({
						method: 'post',
						url: request.FAVORITE,
						data: {
							token: localStorage.getItem('token'),
							tid: this.tid
						}
					}).then((res) => {
						if (res.status) {
							if (res.data.is_favorite) {
								Toast("收藏成功！");
								this.isfavorite = 1;
							} else {
								Toast("取消收藏！");
								this.isfavorite = 0;
							}
							if (this.from == 'detail') {
								this.$parent.modifyShouchang(this.index, this.isfavorite);
							} else {
								this.$parent.$parent.modifyShouchang(this.index, this.isfavorite);
							}
						} else {
							Toast(res.message);
						}
					}).catch((err) => {
						console.log(err);
					})
				}
			},
			//点击点赞
			doLikeFun() {
				if (common.isLogin()) {
					request.apiAxios({
						method: 'post',
						url: request.DOLIKES,
						data: {
							token: localStorage.getItem('token'),
							pid: this.pid
						}
					}).then((res) => {
						if (res.status) {
							debugger;
							if (res.data.is_like) {
								Toast("点赞成功！");
								++this.likeNum;
								console.log(this.likeNum, 'likeNum');
								this.isdolike = 1;
							} else {
								Toast("取消点赞！");
								--this.likeNum;
								this.isdolike = 0;
							}
							if (this.from == 'detail') {
								this.$parent.modifyDianZan(this.index, this.isdolike);
							} else {
								this.$parent.$parent.modifyDianZan(this.index, this.isdolike);
							}
						} else {
							Toast(res.message);
						}
					}).catch((err) => {
						console.log(err);
					})
				}
			},
			//展示评论弹框
			showPinglunAlert() {
				if (common.isLogin()) {
					this.$parent.showPinglunAlert();
				}
			},
			//点击更多功能
			doMore() {
				this.showMore = true;
			},
			closeMore() {
				this.showMore = false;
			},


		}
	});
</script>

<style lang="less">
	.pinglunFunBox {
		height: 1rem;

		.pinglunFunDiv {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1rem;
			display: flex;
			align-items: center;
			justify-content: space-around;
			background: linear-gradient(0deg, #95C5B5, #64A4A4);
			box-shadow: 0px 2px 19px 0px rgba(66, 135, 142, 0.67);

			img {
				height: 0.26rem;
				margin-right: 0.15rem;
				vertical-align: middle;
				display: inline-block;
			}

			span {
				margin-right: 0.2rem;
			}

			.van-button {
				color: #F8FAF5;

				.yes {
					display: none;
				}

				&.yes {
					color: #f08d8c;

					.yes {
						display: inline-block;
					}

					.no {
						display: none;
					}
				}
			}



		}
	}

	.functionDiv {
		margin: 0.2rem 0 0.2rem;
		height: 0.36rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.van-button {
			padding: 0 0.2rem;
			display: inline-flex;
			box-sizing: border-box;
			align-items: center;
			height: 0.36rem;
			line-height: 0.36rem;
			flex: none;
			width: 30%;

			&:last-child {
				width: 10%;
			}

			.van-button__content {
				width: 100%;
				justify-content: center;
				flex: none;
			}

			.van-button__text {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				width: 100%;
			}

			img {
				height: 0.27rem;
				margin-right: 0.1rem;
				vertical-align: middle;
				flex: none;
			}


			.yes {
				display: none;
			}

			&.yes {
				color: #f08d8c;

				.yes {
					display: inline-block;
				}

				.no {
					display: none;
				}
			}
		}
	}
</style>
<style lang="less">
	.pinglunFunDiv,
	.functionDiv {

		.van-button:before,
		.van-button--default {
			background: none;
			border: none;
		}

	}
</style>
